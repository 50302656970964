import React, { useState, useEffect } from 'react';
import homeBanner from "../asset/images/homeBanner.jpg";


import { useTranslation } from 'react-i18next';

function Banner() {
  const [index, setIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const {  i18n } = useTranslation(); // Çeviri işlevini ve i18n nesnesini al
  const texts = i18n.t('banner.text', { returnObjects: true });
  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsVisible(true);
      }, 1000);
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id="home"
      className="banner"
      style={{ backgroundImage: `url(${homeBanner})` }}
    >
      <div className="banner-content">
        <h1 className={isVisible ? "visible" : "invisible"}>
          {texts[index].header}
        </h1>
        <p className={isVisible ? "visible" : "invisible"}>
          {texts[index].paragraph}
        </p>
      </div>
    </div>
  );
}

export default Banner;
