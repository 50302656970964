import React from 'react';

import { useTranslation } from 'react-i18next';
function AboutSection() {
  const {  t } = useTranslation(); // Çeviri işlevini ve i18n nesnesini al
 
  return (
    <div id="about" className="about-section">
      <div className="about-content">
        <p>
        {t('about')}
        </p>
      </div>
      <div>
        <img className="about-logo" src={"isbirligi.jpg"} alt="İşbirliği" />
      </div>
    </div>
  );
}

export default AboutSection;
