import React from 'react';
import { useTranslation } from 'react-i18next';

function ProjectsSection() {
  const {  t } = useTranslation(); // Çeviri işlevini ve i18n nesnesini al

  return (
    <div id="projects" className="projects-section">
      <div className="projects-left">
      
        <h1>{t("project.h2")}</h1>
      </div>
      <div className="projects-right">
        {/* Update your image paths as required */}
        <img src="project1.jpg" alt="" />
        <img src="project2.jpg" alt="" />
        <img src="project3.jpg" alt="" />
        <img src="project4.jpg" alt="" />
        <img src="project5.jpg" alt="" />
        <img src="project7.jpg" alt="" />
        <img src="project8.jpg" alt="" />
        <img src="project6.jpg" alt="" />
      </div>
    </div>
  );
}

export default ProjectsSection;
