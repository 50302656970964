import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next) // i18next ile React bağlantısı
  .use(HttpApi) // HTTP backend plugin
  .init({
    fallbackLng: 'en', // Varsayılan dil
    lng: 'en', // Şu an kullanılan dil
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Çeviri dosyalarının yolu
    },
    interpolation: {
      escapeValue: false, // XSS koruması
    },
    react: {
      useSuspense: true // Eğer Suspense kullanmak istiyorsanız
    }
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
