import React, { useEffect, useRef } from "react";
// Ülkelerin koordinatları ve isimleri
const countries = [
  { name: "Suudi Arabistan", coords: [23.885942, 45.079162] },
  { name: "Kosova", coords: [42.602636, 20.902977] },
  { name: "Dubai", coords: [25.276987, 55.296249] },
  { name: "Arnavutluk", coords: [41.153332, 20.168331] },
  { name: "Irak", coords: [33.223191, 43.679291] },
  { name: "Cezayir", coords: [28.033886, 1.659626] },
  { name: "Macaristan", coords: [47.162494, 19.503304] },
  { name: "Rusya", coords: [55.751244, 37.618423] },
  { name: "Libya", coords: [26.3351, 17.228331] },
  { name: "Özbekistan", coords: [41.377491, 64.585262] },
  { name: "Kırgızistan", coords: [41.20438, 74.766098] },
  { name: "Amerika", coords: [37.09024, -95.712891] },
  { name: "Türkiye", coords: [38.963745, 35.243322] },
];
const Map = () => {
  const mapContainerRef = useRef(null);
  const mapInstanceRef = useRef(null); // Harita instance'ını tutmak için bir ref daha

  useEffect(() => {
    const ymaps = window.ymaps;
    if (ymaps && ymaps.ready) {
      ymaps.ready(() => {
        if (!mapInstanceRef.current) { // Eğer harita zaten oluşturulmamışsa oluştur
          const myMap = new ymaps.Map(mapContainerRef.current, {
            center: [55.751574, 37.573856], // Moskova koordinatları
            zoom: 2.4,
            controls: [],
          });

          countries.forEach((country) => {
            const placemark = new ymaps.Placemark(country.coords, {
              balloonContent: country.name,
            });
            myMap.geoObjects.add(placemark);

            placemark.events.add("mouseenter", function () {
              placemark.balloon.open();
            });

            placemark.events.add("mouseleave", function () {
              placemark.balloon.close();
            });
          });

          mapInstanceRef.current = myMap; // Haritayı ref'e ata
        }
      });
    }
  }, []); // Bağımlılıkları boş bırak

  return <div ref={mapContainerRef} style={{ height: 400, width: "100%" ,filter:"grayscale(1)"}} />;
};

export default Map;
