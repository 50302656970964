import "./App.css";
import React from "react";

import Navbar from "./components/Navbar";

import Banner from "./components/Banner";
import AboutSection from "./components/AboutSection ";
import ServicesSection from "./components/ServicesSection ";
import ContactSection from "./components/ContactSection ";
import ProjectsSection from "./components/ProjectsSection ";
import Map from "./components/Map";
import SparePartSection from "./components/SparePartSection"
function App() {
 

  return (
    <div className="app">
      <Navbar />
      <Banner />
      <div className="content-container">
        <AboutSection />
        <ProjectsSection />
        <SparePartSection />
        <ServicesSection />
        <ContactSection />
      </div>
      <Map />
    </div>
  );
}

export default App;
