import React from "react";
import { useTranslation } from "react-i18next";

function SparePartSection() {
  const { t } = useTranslation(); // Çeviri işlevini ve i18n nesnesini al

  return (
    <div id="spareparts" className="sparepart-section">
      <div className="sparepart-left">
        <h1>{t("sparepart.h2")}</h1>
      </div>
      <div className="sparepart-right">
        {/* Update your image paths as required */}
        <img src="sparepart1.jpg" alt="" />
        <img src="sparepart2.jpg" alt="" />
        <img src="sparepart3.jpg" alt="" />
        <img src="sparepart4.jpg" alt="" />
        <img src="sparepart5.jpg" alt="" />
        <img src="sparepart6.jpg" alt="" />
        <img src="sparepart7.jpg" alt="" />
        <img src="sparepart8.jpg" alt="" />
        <img src="sparepart9.jpg" alt="" />
       
        <img src="sparepart15.jpg" alt="" />
        <img src="sparepart16.jpg" alt="" />
        <img src="sparepart17.jpg" alt="" />
        <img src="sparepart18.jpg" alt="" />
        <img src="sparepart19.jpg" alt="" />
        <img src="sparepart20.jpg" alt="" />
      </div>
    </div>
  );
}

export default SparePartSection;
