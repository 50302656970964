import React from 'react';
import { useTranslation } from 'react-i18next';
import { Eğitim, Montaj, Revize, TeknikServis, YedekParca ,Danismanlik} from "../asset/icons/icon";

function ServicesSection() {
  const { t } = useTranslation();
  const servicesData = [
    { icon: Eğitim, title: t('services.education.title'), text: t('services.education.text') },
    { icon: Danismanlik, title: t('services.consultancy.title'), text: t('services.consultancy.text') },
    { icon: Montaj, title: t('services.assembly.title'), text: t('services.assembly.text') },
    { icon: Revize, title: t('services.revision.title'), text: t('services.revision.text') },
    { icon: TeknikServis, title: t('services.technicalService.title'), text: t('services.technicalService.text') },
    { icon: YedekParca, title: t('services.spareParts.title'), text: t('services.spareParts.text') }
  ];

  return (
    <div id="services" className="services-section">
      <div className="section-left">
        <h1>{t('services.h2')}</h1>
      </div>
      <div className="services-right">
        {servicesData.map((service, index) => (
          <div className="services-card" key={index}>
            <h2>{service.title}</h2>
            {service.icon}
            <p className="services-content">{service.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ServicesSection;
