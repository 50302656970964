import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("#home"); // Ensure initial active link is correctly set
  const {  i18n } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = (link) => {
    console.log("Setting active link to:", link); // Debug: log which link is being activated
    setActiveLink(link);
    setIsOpen(false); // Close menu after selection
  
    const section = document.querySelector(link);
    if (section) {
      const topOffset = section.getBoundingClientRect().top + window.scrollY - 300; // 100px offset for a smoother scroll
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    } else {
      console.error("No section found for link:", link);
    }
  };
  

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false); // Close menu when language is changed
  };

  // Ensure links are correctly retrieved from your i18n setup
  const links = i18n.t('navbar.links', { returnObjects: true });

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <img src="logo.svg" alt="" />
       
      </div>
      <button className="menu-button" onClick={toggleMenu}>
        {isOpen ? "×" : "☰"}
      </button>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        {links.map((link) => (
          <span
            key={link.id}
            className={`link-span ${activeLink === link.id ? "active" : "inactive"}`}
            onClick={() => handleLinkClick(link.id)}
          >
            {link.text}
          </span>
        ))}
        <img className="locale" onClick={() => changeLanguage('en')} src="en.png" alt="EN" />
        <img className="locale" onClick={() => changeLanguage('tr')} src="tr.png" alt="TR" />
    
      </div>
    </nav>
  );
};

export default Navbar;
