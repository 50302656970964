import React, { useState } from "react";
import { useTranslation } from "react-i18next";


function ContactSection() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // name, email ve message bilgilerini tek bir 'text' parametresi olarak formatlayın
    const formattedText = `Name: ${formData.name.trim()}\nEmail: ${formData.email.trim()}\nMessage: ${formData.message.trim()}`;

    try {
      const response = await fetch("https://maill.dmsandwichpanel.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject: formData.subject,
          text: formattedText,
        }),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        console.log("Success:", jsonResponse);
        alert(t("contact.contact.success"));
      } else {
        throw new Error("Failed to send message");
      }
    } catch (error) {
      console.error("Error:", error);
      alert(t("contact.contact.error"));
    }
  };

  return (
    <div id="contact" className="contact-section">
      <div className="contact-left">
        <h1>{t("contact.contact.h2")}</h1>
        <p>{t("contact.contact.p")}</p>

        <div className="contact-icon">
          <a
            href="https://wa.me/+905317105034"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-link"
          >
            <img src="whatsapp.svg" alt="WhatsApp" />
            <div className="flags">
              <img src="de.svg" alt="German" title="Deutsch" />
              <img src="en.svg" alt="English" title="English" />
            </div>
          </a>
          <a
            href="https://wa.me/+905447234275"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-link"
          >
            <img src="whatsapp.svg" alt="WhatsApp" />
            <div className="flags">
              <img src="tr.svg" alt="Turkey" title="Turkish" />
            </div>
          </a>
          <a href="mailto:mehmettas081@icloud.com" className="email-link">
            <img src="gmail.svg" alt="Gmail" />
            <span className="notification">1</span>
          </a>
          <a href="mailto:mehmettas06.mt@gmail.com" className="email-link">
            <img src="gmail.svg" alt="Gmail" />
            <span className="notification">2</span>
          </a>
        </div>
      </div>
      <div className="contact-right">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="contact-head">
            <input
              type="text"
              name="name"
              placeholder={t("contact.contact.name")}
              onChange={handleInputChange}
              required
            />
            <input
              type="text"
              name="subject"
              placeholder={t("contact.contact.subject")}
              onChange={handleInputChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder={t("contact.contact.mail")}
              onChange={handleInputChange}
              required
            />
          </div>
          <textarea
            name="message"
            placeholder={t("contact.contact.message")}
            rows="10"
            onChange={handleInputChange}
            required
          ></textarea>
          <button type="submit" className="send-button">
            {t("contact.contact.button")}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactSection;
